import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import anything from '../../images/share-thumb.png';
import '../../assets/sass/main.scss';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Radio Gosha' },
            { name: 'keywords', content: 'james franzen, goshadole, visual, animation, animator' },
          ]}
        >
          <meta property="og:title" content="Radio Gosha" />
          <meta property="og:description" content="The art of James Franzen" />
          <meta property="og:image" content={anything} />
          <html lang="en" />
        </Helmet>
        <div>{children}</div>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
